<template>
  <div class="business-list">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="handleExcelModal">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="ml-3">
            <router-link :to="pathNameAddBusiness">
              <b-button variant="primary" size="sm" class="font-weight-bolder">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
<!--        <div class="col-md-12 mb-5">-->
<!--              <b-form>-->
<!--                <b-row>-->
<!--                  <b-col md="3" class="pl-0">-->
<!--                    <b-input-->
<!--                      placeholder="Tìm kiếm"-->
<!--                      v-model="search"-->
<!--                      append-icon="search"-->
<!--                      single-line-->
<!--                      hide-details-->
<!--                      class="btn-sm"-->
<!--                      v-on:keyup.enter="getBusinesses()"-->
<!--                    ></b-input>-->
<!--                  </b-col>-->
<!--                  <b-col md="1">-->
<!--                    <b-button-->
<!--                      style="fontweight: 600; width: 70px"-->
<!--                      variant="primary"-->
<!--                      size="sm"-->
<!--                      @click="onFilter()"-->
<!--                      >Lọc</b-button-->
<!--                    >-->
<!--                  </b-col>-->
<!--                  <b-col></b-col>-->
<!--                </b-row>-->
<!--              </b-form>-->
<!--            </div>-->
        <b-table
          :items="businessList"
          :fields="businessFields"
          :busy="onLoading"
          bordered
          hover
        >
          <template v-slot:cell(id)="row">
            <div>
              <span
                v-text="row.item.id"
                class="d-flex justify-content-center"
              ></span>
            </div>
          </template>
          <template v-slot:cell(createdAt)="row">
            <div>
              <span
                v-text="row.item.createdAt"
                class="d-flex justify-content-center"
              ></span>
              <span
                v-text="row.item.createdByName"
                class="d-flex justify-content-center"
              ></span>
            </div>
          </template>
          <template v-slot:cell(updatedAt)="row">
            <div>
              <span
                v-text="row.item.updatedAt"
                class="d-flex justify-content-center"
              ></span>
              <span
                v-text="row.item.updatedByName"
                class="d-flex justify-content-center"
              ></span>
            </div>
          </template>
          <template v-slot:cell(statusStyle)="data">
            <span
              v-text="data.item.statusStyle.name"
              class="label font-weight-bold label-lg label-inline"
              :class="data.item.statusStyle.class"
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="onEditBusinessById(row.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>

                <b-dropdown-item @click="onDeleteBusiness(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-show="hasPagination"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="getBusinesses()"
              align="right"
              pills
            >
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';

import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { TIME_OUT } from '@/utils/constants';

const KTCodePreview = () => import(/* webpackPrefetch: true */ '@/view/content/CodePreview.vue');

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      pathNameAddBusiness: { name: 'add-business' },
      title: '',
      filterOption: {
        name: null,
      },
      businessList: [],
      installments: [],
      onLoading: false,
      numberOfPage: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      businessFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên doanh nghiệp',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
        },
        {
          key: 'taxNo',
          label: 'Mã số thuế',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
        },
        {
          key: 'createdAt',
          label: 'Tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'updatedAt',
          label: 'Cập nhật',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'statusStyle',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  computed: {
    ...mapGetters(['breadcrumbs']),
    hasPagination() {
      return this.numberOfPage >= 2;
    },
  },
  mounted() {
    this.title = 'Danh sách khách hàng doanh nghiệp';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.getBusinesses();
  },
  methods: {
    makeToastSuccess,
    makeToastFaile,
    async getBusinesses() {
      if (this.onLoading) return;
      this.onLoading = true;
      try {
        this.page = this.$route.query.page || 1;

        const params = this.getFilterParams();

        const response = await ApiService.query(
          '/businesses',
          { params },
          { timeout: TIME_OUT },
        );
        const dataset = response.data.data;
        this.totalItems = dataset.total;
        this.numberOfPage = dataset.total_page;
        this.businessList = dataset.data;
      } catch (error) {
        this.makeToastFaile(error);
      } finally {
        this.onLoading = false;
      }
    },
    statusClasses(statusStyle) {
      return `${statusStyle.class}`;
    },
    onEditBusinessById(id) {
      this.$router.push({
        name: 'update-business',
        params: { id },
      });
    },
    getFilterParams() {
      return {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        name: this.name || '',
      };
    },
    async deleteBusinessById(id) {
      try {
        await ApiService.delete(`businesses/${id}`);

        this.makeToastSuccess('Xóa thành công');

        this.getBusinesses();
      } catch (error) {
        this.makeToastFailed(error.message);
      }
    },
    onDeleteBusiness(item) {
      Swal.fire({
        title: `${item.name} - ${item.taxNo}`,
        text: 'Bạn có chắc xóa ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteBusinessById(item.id);
        }
      });
    },
    handleExcelModal() {
      alert('Tính năng đang phát triển');
    },
    searchPartnerApis(textInput, keyParamSearch) {
      this.filterOption[keyParamSearch] = textInput;
      this.getBusinesses();
    },
  },
};
</script>

<style lang="scss" scoped>
.business-list {
  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  .page-item.disabled {
    cursor: not-allowed;

    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
